import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ContentCard, Lineup } from '../'
import { Dropdown } from 'primereact/dropdown'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'
import { CONF } from '../../../configs'

import DesktopContext from '../../../Layouts/Desktop/ctx'
import MobileContext from '../../../Layouts/Mobile/ctx'

const TopLineup = ({ scope, subjectId, theme, setToursArr, pathname }) => {
    const [data, setData] = useState(null)
    const [idx, setIdx] = useState(null)
    const [splittedMode, setSplittedMode] = useState(false)

    const { id } = useParams()
    const fromParams = id || null

    const ctx = useContext(DesktopContext)
    const mctx = useContext(MobileContext)

    const { federation } = CONF

    useEffect(() => {
        if(idx !== null && data[idx] && !data[idx].lineup && !data[idx].avgLineUp) {
            axios.get(`${ENDPOINT}client/getTournamentDreamLineups/${data[idx]._id}?fid=${federation ? federation._id : subjectId}`)
                .then(resp => {
                    if(resp.data && !resp.data.error) {
                        setData(data.map((t, i) => ({
                            ...t,
                            lineup: i === idx ? resp.data.lineup : t.lineup,
                            avgLineUp: i === idx ? resp.data.avgLineUp : t.avgLineUp
                        })))
                    }
                })
        }
    }, [idx])

    useEffect(() => {
        if(scope) {
            if(scope === 'federation') {
                axios.get(`${ENDPOINT}client/tournaments_navi/${subjectId}`)
                    .then(resp => {
                        setData(resp.data.map(({ name, _id }) => ({_id, name, lineup: null})))
                        if(resp.data[0]) {
                            setIdx(0)
                        }
                        setToursArr(resp.data.map(t => t._id))
                    })
            } else if(scope === 'tournament') {
                axios.get(`${ENDPOINT}client/getTournamentDreamLineups/${id}?fid=${federation._id}`)
                    .then(resp => {
                        if(resp && resp.data) {
                            setData(resp.data)
                            if(resp.data.lineups) {
                                setIdx(0)
                                setSplittedMode(resp.data.lineups.length)
                            }
                        }
                    })
            } else {
                axios.get(`${ENDPOINT}client/toplineup/${fromParams || subjectId}?scope=${scope}`)
                    .then(resp => {
                        setData(resp.data)
                    })
            }
        }
    }, [scope, id])

    const hasdropDown = !fromParams || (data && data.lineups && data.lineups.length > 1)

    return  <div
                className={'top-lineup '+(!hasdropDown ? ' no-dropdown' : '')}
                style={theme ? {background: theme.surface} : {}}
            >
                {data && data.name ? (
                    <Helmet>
                        <title>{`${data.name} | Статистика и календарь турнира`}</title>
                    </Helmet>
                ) : null}

                <ContentCard
                    title={scope === 'federation' ? 'Символические сборные' : 'Сборная турнира'}
                    theme={{
                        surface: theme ? theme.surface : 'var(--widget-card-gradient)',
                        shadowColor: 'var(--widget-card-shadow-color)',
                        titleSurface: 'var(--widget-card-title-bgd)',
                        titleColor: 'var(--widget-card-title-color)'
                    }}
                >
                    {!fromParams ? (
                        <Dropdown
                            value={idx}
                            options={data ? data.map((t, ix) => ({value: ix, label: t.name})) : []}
                            onChange={e => setIdx(e.target.value)}
                        />
                    ) : data && data.lineups && data.lineups.length > 1 ? (
                        <Dropdown
                            value={idx}
                            options={data.lineups.map((l, ix) => ({value: ix, label: l.name}))}
                            onChange={e => setIdx(e.target.value)}
                        />
                    ) : null}

                    <Lineup
                        width={300}
                        idx={idx}
                        pathname={pathname}
                        mvp={data && data.lineups && data.lineups[idx] ? data.lineups[idx].mvpId : null}
                        manager={data && data.lineups && data.lineups[idx] ? data.lineups[idx].managerDream : null}
                        data={data ? splittedMode && data.lineups && data.lineups[idx] ? data.lineups[idx] : data.avgLineUp ? data.avgLineUp : fromParams ? data.lineup : data[idx] ? data[idx].lineup || data[idx].avgLineUp : null : null}
                        extended={['tournament', 'federation'].includes(scope)}
                        theme={{
                            numFill: 'var(--blue-600)',
                            numColor: 'var(--blue-100)',
                            mutedColor: 'var(--blue-300)',
                            numShadow: 'var(--blue-700)',
                            nameColor: theme ? theme.nameColor : 'var(--blue-50)'
                        }}
                    />
                </ContentCard>
            </div>
}

export default TopLineup
