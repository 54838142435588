import React, {useState, useEffect, useRef} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'

import axios from "axios";
import {ENDPOINT} from "../../env";
import { tabs, navi } from './config'

import { ContentTabs, IconsNavi } from '../Atoms'
import Content from './Content'

import {OverlayPanel} from "primereact/overlaypanel";
import {Skeleton} from "primereact/skeleton";

import './style.scss'

const SubContent = ({ active, path, setNav, nav, tab, playoff }) => {
    return  <div
                className={`subcontent${active ? ' active' : ''}`}
            >
                {navi[tab] ? navi[tab].map((item, k) => (
                    <div key={k} onClick={() => setNav(k)} className={`subcontent__item${nav === k ? ' active' : ''}`}>
                        <i className='pi pi-shield'/>
                        {item.label}
                    </div>
                )) : null}
                {/*{navi[tab] ? <IconsNavi
                    layout='desktop'
                    iconsStyle={'lineal'}
                    active={nav}
                    theme={{
                        activeSurface: 'blue-50',
                        activeAccent: 'blue-600',
                        activeShadow: 'rgba(54, 120, 230, 0.2)',
                        surface: 'orange-50',
                        accent: 'bluegray-600',
                        shadow: '#f1e7e0'
                    }}
                    items={playoff && tab === 0 ? [{path: 'standings', icon: 'trophy', label: 'сетка'}].concat(navi[0].slice(2)) : navi[tab]}
                    onChange={i => setNav(i)}
                /> : null}*/}
            </div>
}

const Tournament = ({ layout, width, theme }) => {
    const op = useRef(null);
    const opDocs = useRef(null);
    const [data, setData] = useState(null)
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [tab, setTab] = useState(0)
    const [nav, setNav] = useState(0)
    const [playoff, setPlayoff] = useState(false)
    const [overrideNavi, setOverrideNavi] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        setNav(0)
        setTab(0)
        setLoading(true)
        axios.get(`${ENDPOINT}client/getTournamentData/${id}`)
            .then(resp => {
                if (resp && resp.data && resp.data.success) {
                    setData(resp.data.data)
                    setLoading(false)
                }
            })
    }, [id])

    useEffect(() => {
        setNav(0)
    }, [tab, playoff])

    const handleChangeId = (newId) => {
        const pathParts = location.pathname.split('/');
        pathParts[pathParts.length - 1] = newId;
        const newPath = pathParts.join('/');
        history.push(newPath);
    };

    return  layout === 'mobile' ? <div
                className={`tournament ${layout}`}
                style={{
                    width: width
                }}
            >
                {layout !== 'mobile' ? <ContentTabs
                    model={tabs}
                    width={width}
                    theme='blue'
                    activeIdx={tab}
                    onChange={e => setTab(e.index)}
                /> : null}

                {navi[tab] ? <IconsNavi
                    layout='mobile'
                    iconsStyle={'lineal'}
                    active={nav}
                    theme={{
                        activeSurface: 'blue-50',
                        activeAccent: 'blue-600',
                        activeShadow: 'rgba(54, 120, 230, 0.1)',
                        surface: 'orange-50',
                        accent: 'bluegray-600',
                        shadow: '#FFF8F3'
                    }}
                    items={overrideNavi ? overrideNavi[tab] : navi[tab]}
                    onChange={i => setNav(i)}
                /> : null}

                <Content
                    tabs={tabs}
                    _navi={navi}
                    activeTab={tab}
                    activeNav={nav}
                    width={width}
                    layout={layout}
                    setPlayoff={setPlayoff}
                    isPlayoff={playoff}
                    setOverrideNavi={setOverrideNavi}
                />
            </div> : (
                <div
                    className={`tournament ${layout}`}
                    style={{
                        width: width,
                        backgroundColor: theme.surface.includes('#') ? theme.surface : `var(--${theme.surface})`
                    }}
                >
                    <div className="container">
                        <div className='tournament__top'>
                            {loading ? <Skeleton width='240px' height='70px'/> : !!data && (
                                <div className='tournament__info'>
                                    <div className='tournament__emblem'>
                                        <img src={data.emblem}/>
                                    </div>
                                    <div className='tournament__text alterfont'>
                                        <div className='tournament__name'>{data.name}</div>
                                        <div className="tournament__seasons">
                                            {data.season?.name}
                                            {data.familyTourns?.length > 0 && (
                                                <div onClick={(e) => op.current.toggle(e)} className="tournament__seasons-btn">
                                                    выбрать другой
                                                </div>
                                            )}
                                            <OverlayPanel ref={op} id="overlay_panel">
                                                {data.familyTourns?.map((a) => (
                                                    <div key={a._id} onClick={(e) => {
                                                        op.current.toggle(e);
                                                        handleChangeId(a._id)
                                                    }}>
                                                        <i className="pi pi-history"/>
                                                        {a.name}
                                                    </div>
                                                ))}
                                            </OverlayPanel>
                                        </div>
                                        {(data.dateStart && data.dateEnd) && <div>с {data.dateStart} до {data.dateEnd}</div>}
                                    </div>
                                </div>
                            )}
                            <div className='content-btns'>
                                <div className='content-btns__tabs'>
                                    {tabs.map((t, i) => (
                                        <div
                                            className='content-btn alterfont'
                                            key={i}
                                            onClick={() => setTab(i)}
                                            // style={{
                                            //     background: tab === i ? `var(--blue-500)` : '#fff',
                                            //     color: tab === i ? `var(--blue-50)` : `var(--blue-500)`,
                                            //     boxShadow: `2px 2px ${tab === i ? '25px' : '10px'} var(--blue-${tab === i ? '50' : '100'})`
                                            // }}
                                        >
                                            <div className={`pi pi-${t.icon}`}></div>
                                            <div>{t.label}</div>
                                        </div>
                                    ))}
                                    {data?.attachments?.length > 0 && (
                                        <div className='content-btn alterfont' onClick={e => opDocs.current.toggle(e)}>
                                            <i className='pi pi-file'/>
                                            <div>Документы</div>
                                        </div>
                                    )}
                                    <OverlayPanel ref={opDocs} id="overlay_panel">
                                        {data?.attachments?.map((a) => (
                                            <div key={a._id} onClick={() => window.open(a.path, '_blank')}>
                                                <i className="pi pi-link"/>
                                                {a.publicname}
                                            </div>
                                        ))}
                                    </OverlayPanel>
                                </div>
                                {tabs.map((t, i) => <SubContent active={tab === i} path={t.path} setNav={setNav} nav={nav} tab={tab} playoff={playoff}/>)}
                            </div>
                        </div>

                        <Content
                            tabs={tabs}
                            _navi={navi}
                            activeTab={tab}
                            activeNav={nav}
                            width={width}
                            layout={layout}
                            setPlayoff={setPlayoff}
                            isPlayoff={playoff}
                        />
                    </div>
                </div>
    )
}

export default Tournament
