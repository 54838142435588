import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Tag } from 'primereact/tag'
import { Emblem } from '../../Atoms'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const Search = ({ setSuggestions, isDesktop, rightAligned, federation }) => {
    const [query, setQuery] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [evtContainer, setEvtContainer] = useState(null)
    const [suggs, setSuggs] = useState(null)

    const opRef = useRef()
    const history = useHistory()

    useEffect(() => {
        if(query && query.length > 3 && !fetching) {

            setFetching(true)
            axios.get(`${ENDPOINT}client/suggestData/${federation._id}?query=${query}`)
                .then(resp => {
                    if(resp.data) {
                        const formatted = ['teams', 'players'].reduce((acc, key) => {
                            const pull = resp.data[key].slice(0, 10)
                            acc = acc.concat(pull.map(item => key === 'teams' ? ({
                                info: item.name,
                                img: item.emblem ? item.emblem : item.club ? item.club.emblem || '' : '',
                                type: 'team',
                                _id: item._id,
                                url: `/club/${item.club._id}?team=${item._id}`
                            }) : ({
                                info: item.name+' '+item.surname,
                                img: item.avatarUrl || '',
                                type: 'player',
                                _id: item._id,
                                url: `/player/${item._id}`
                            })))

                            return acc
                        }, [])

                        setFetching(false)

                        if(isDesktop && evtContainer) {
                            if(formatted.length) {
                                setSuggs(formatted)
                                opRef.current.show(evtContainer)
                            }
                        } else {
                            setSuggestions(formatted)
                        }
                    } else {
                        setFetching(false)
                        if(isDesktop) {
                            setSuggs([])
                            opRef.current.hide(evtContainer)
                        } else {
                            setSuggestions([])
                        }
                    }
                })
        }
    }, [query])

    return  <div className={'search'+(isDesktop ? ' _desktop' : '')+(rightAligned ? ' _rightAligned' : '')}>
                <span className="p-input-icon-left">
                    <i className={`pi ${fetching ? 'pi-spin pi-spinner' : 'pi-search'}`} />
                    <InputText
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value)
                            setEvtContainer(e)
                        }}
                        className='p-inputtext-sm'
                        placeholder='поиск игрока или команды'
                    />
                </span>
                {isDesktop ? (
                    <OverlayPanel
                        ref={opRef}
                        className='desktop-search_op'
                    >
                    {suggs && suggs.length ? (
                        <div className='search-suggestions'>
                            <div className='top'>
                                <Tag icon='pi pi-star-fill' value='Результаты поиска' />
                            </div>

                            <div className='items'>
                                {suggs.map((f, i) => (
                                    <div className='item' onClick={() => {
                                        setQuery(null)
                                        opRef.current.hide(evtContainer)
                                        setFetching(false)
                                        history.push(f.url)
                                    }}>
                                        <Emblem size='sm' source={f.img} backdroped={true} player={f.type === 'player'}/>
                                        <div className='name alterfont'>{f.info}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    </OverlayPanel>
                ) : null}
            </div>
}

export default Search
