import React, { useRef } from "react";

import { CONF } from "../../configs";

import { OverlayPanel } from "primereact/overlaypanel";

import "./style.scss";

const Footer = ({ layout, theme, width, data, device, isInstallable, onForcedInstall, federation }) => {
    const op = useRef(null);
    console.log(federation);
    const store = layout === "mobile" ? (device && device.os && device.os.name === "iOS" ? "appstore" : "googleplay") : null;

    const footerBanners =
        CONF.footerBanners && CONF.footerBanners.length
            ? CONF.footerLinks && CONF.footerLinks.length
                ? CONF.footerBanners.map((i, index) => ({ href: i, link: CONF.footerLinks[index] }))
                : CONF.footerBanners.map((i) => ({ href: i }))
            : [];

    // layout !== "mobile" ? (
    //     <div
    //         className={`footer ${layout}`}
    //         style={{
    //             width: width,
    //             backgroundColor: theme.surface.includes("#") ? theme.surface : `var(--${theme.surface})`,
    //         }}
    //     >
    //         {CONF.footerStores ? <div className="desc alterfont">{CONF.footerStores[store] && CONF.footerStores[store].includes("https") ? "доступно в" : CONF.footerStores.releaseDesc}</div> : null}
    //         {CONF.footerStores ? (
    //             <div className={`store_btn ${store}`} onClick={() => (CONF.footerStores[store] && CONF.footerStores[store].includes("https") ? window.open(CONF.footerStores[store]) : null)}></div>
    //         ) : null}

    //         {isInstallable && store ? (
    //             <div
    //                 className="installTrigger"
    //                 onClick={() => {
    //                     localStorage.removeItem("NO_INSTALL_CTA");
    //                     onForcedInstall();
    //                 }}
    //             >
    //                 <span className="vendor">
    //                     <i className={`pi pi-${store.includes("google") ? "android" : "apple"}`}></i>
    //                 </span>
    //                 <div className="description">
    //                     <span>Установить приложение</span>
    //                 </div>
    //             </div>
    //         ) : null}

    //         <div className="footer_socials">
    //             {Object.entries(CONF.footerSocials).map((e, i) => (
    //                 <div
    //                     key={i}
    //                     className={`footer_socials_item __${e[0]}`}
    //                     onClick={() => window.open(e[1])}
    //                     style={{
    //                         backgroundImage: `url(${require("../../assets/img/social_" + e[0] + ".png")})`,
    //                     }}
    //                 ></div>
    //             ))}
    //         </div>

    //         {footerBanners.length ? (
    //             <div class="footer-banners">
    //                 {footerBanners.map((b, i) => (
    //                     <a href={b.link}>
    //                         <img src={b.href} key={i} />
    //                     </a>
    //                 ))}
    //             </div>
    //         ) : null}

    //         <div className="footer_contacts">
    //             <div className="footer_contacts_emblem">
    //                 <img src={federation.emblem} />
    //             </div>

    //             <div
    //                 className="footer_contacts_content"
    //                 style={{
    //                     color: `var(--${theme.text})`,
    //                 }}
    //             >
    //                 <div>{layout === "mobile" ? <a href={`tel:${CONF.footerCreds.phone}`}>{CONF.footerCreds.phone}</a> : CONF.footerCreds.phone}</div>
    //                 <div>{CONF.footerCreds.address}</div>
    //                 <div>{CONF.footerCreds.copyright}</div>
    //             </div>
    //         </div>
    //     </div>
    // ) : (
    return (
        <div
            className={`footer ${layout}`}
            style={{
                width: width,
                backgroundColor: theme.surface.includes("#") ? theme.surface : `var(--${theme.surface})`,
            }}
        >
            <div className="container">
                <div
                    className="footer_contacts"
                    style={{
                        color: `var(--${theme.text})`,
                    }}
                >
                    <div className="footer_contacts_emblem">
                        <img src={federation.emblem} />
                    </div>

                    <div className="footer_contacts__block">
                        <div>{federation.name}</div>
                        <div>{federation.address}</div>
                        {!!federation.inn && <div>ИНН: {federation.inn}</div>}
                    </div>

                    <div className="footer_contacts__block">
                        <div>{federation.phone}</div>
                        <div>{federation.email}</div>
                        <div className="footer_socials">
                            {!!federation.outerLinks && Object.keys(federation.outerLinks).map((e, i) => !!federation.outerLinks[e] && (
                                <div
                                    key={i}
                                    className={`footer_socials_item __${e}`}
                                    onClick={() => window.open(federation.outerLinks[e], "_blank")}
                                    style={{
                                        backgroundImage: `url(${require("../../assets/img/social_" + e + ".png")})`,
                                    }}
                                />
                            ))}
                        </div>
                    </div>

                    {federation.attachments?.filter(a => !a.archived)?.length > 0 && (
                        <div className="footer_contacts__block">
                            <div onClick={(e) => op.current.toggle(e)} className="footer_contacts__document">
                                Документы
                            </div>
                            <OverlayPanel ref={op} id="overlay_panel">
                                {federation.attachments?.filter(a => !a.archived)?.map((a) => (
                                    <div key={a._id} onClick={() => window.open(a.path, "_blank")}>
                                        <i className="pi pi-link"/>
                                        {a.publicname}
                                    </div>
                                ))}
                            </OverlayPanel>
                        </div>
                    )}
                </div>

                {footerBanners.length ? (
                    <div class="footer-banners">
                        {footerBanners.map((b, i) => (
                            <a href={b.link}>
                                <img src={b.href} key={i} />
                            </a>
                        ))}
                    </div>
                ) : null}

                {CONF.footerStores ? (
                    <div className="footer-stores">
                        {Object.entries(CONF.footerStores)
                            .filter((e) => e[0] !== "releaseDesc")
                            .map((e, i) => [
                                <div className="desc alterfont">{e[1] && e[1].includes("https") ? "доступно в" : CONF.footerStores.releaseDesc}</div>,
                                <div key={i} className={`store_btn ${e[0]}`} onClick={() => (e[1] && e[1].includes("https") ? window.open(e[1]) : null)}></div>,
                            ])}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Footer;
