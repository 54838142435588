import React, {useState, useEffect, useContext} from 'react'

import { ENDPOINT } from '../../../env'
import axios from 'axios'
import {postByUrl} from "../../../v3MethodsService";
import {GlobalContext} from "../../../ctx";
import DesktopContext from "../../../Layouts/Desktop/ctx";
import { injectQueryPath } from '../../../utils'

import { Emblem } from '../../Atoms'

import { Tag } from 'primereact/tag'
import { Skeleton } from 'primereact/skeleton'

import vk from './img/vk.svg'
import tg from './img/tg.svg'
import yt from './img/yt.svg'

import './style.scss'

const formatTeamName = str => {
    if(/\(*\)/.test(str)) {
        return str.split('(')[1].split(')')[0]
    } else {
        return str
    }
}

const checkFavorites = (clubId, arr) => {
    return arr ? arr.find(el => el._id === clubId || el.url.includes(clubId)) : false
}

const Top = ({ data, team, setTeam, device }) => {
    const dctx = useContext(DesktopContext)
    const { setAccountOpen } = dctx
    const [global, setGlobal] = useContext(GlobalContext)
    const { favorites } = global && global.account || []
    const [favoritedBy, setFavoritedBy] = useState(data ? data.favoritedBy || 0 : 0)
    const [inFavorites, setInfavorites] = useState(false)

    useEffect(() => {
        if(favorites && favorites.length) {
            setInfavorites(checkFavorites(data ? data._id : null, favorites))
        }
    }, [favorites])

    useEffect(() => {
        if(data && (data.favoritedBy !== favoritedBy)) {
            setFavoritedBy(data.favoritedBy)
        }
    }, [data])

    const toggleSub = () => {
        if(!(global && global.account)) {
            setAccountOpen(true)
        } else {
            const newFavorites = inFavorites ? [...favorites].filter(f => !f.url.includes(data._id)) : [...favorites].concat([{
                img: data.emblem || null,
                type: 'team',
                info: data.name,
                _id: team || data._id,
                url: `/club/${data._id}${team ? '?team='+team : ''}`
            }])
            postByUrl('clientAuth/changeProfile', {favorites: newFavorites}).then(resp => {
                setFavoritedBy(inFavorites ? (favoritedBy - 1) : (favoritedBy + 1))
                setInfavorites(!inFavorites)
                setGlobal(prev => ({...prev, account: {...prev.account, favorites: newFavorites}}))
            })
            /*axios.put(`${ENDPOINT}user/${appUserId}`, {
                favorites: inFavorites ? [...favorites].filter(f => !f.url.includes(data._id)) : [...favorites].concat([{
                    img: data.emblem || null,
                    type: 'team',
                    info: data.name,
                    _id: team || data._id,
                    url: `/club/${data._id}${team ? '?team='+team : ''}`
                }])
            })
            .then(resp => {
                setFavoritedBy(inFavorites ? (favoritedBy - 1) : (favoritedBy + 1))
                setInfavorites(!inFavorites)
            })*/
        }
    }

    return (
        <div className='club-top'>
            <div className={`club-top__intro ${device ? device : ''}`}>
                {data ? <Emblem size='lg' source={data.emblem || ''} backdroped={true}/> : <Skeleton width={128} height={128}/>}

                <div className='club-top__primary'>
                    {data ? (
                        <div className='club-top__name'>
                            <span className='alterfont'>{data.name}</span>
                            {!!data.socials && Object.keys(data.socials)?.length > 0 && Object.keys(data.socials).map((s, k) => !!data.socials[s] && <img key={k} src={socials[s]} onClick={() => window.open(data.socials[s], '_blank')}/>)}
                        </div>
                    ) : <Skeleton width={400} height={36}/>}
                    {data ? (
                        <div className='club-top__location'>
                            <span>{data.city?.name}, {data.region?.name}</span>
                        </div>
                    ) : <Skeleton width={190} height={17}/>}

                    {inFavorites ? (
                        <Tag className='sub negative' icon='pi pi-times' value='Отписаться'
                             onClick={() => toggleSub()}/>
                    ) : (
                        <Tag className='sub positive' icon='pi pi-bell' value='Подписаться'
                             onClick={() => toggleSub()}/>
                    )}
                </div>

                <div className='club-top__primary'>
                    {data ? (
                        !!(data.colors?.mainColor || data.colors?.extraColor) && (
                            <div className='club-top__location'>
                                <span>клубные цвета:</span>
                                {!!data.colors?.mainColor && <div className='club-top__color' style={{background: `#${data.colors?.mainColor}`}}/>}
                                {!!data.colors?.extraColor && <div className='club-top__color' style={{background: `#${data.colors?.extraColor}`}}/>}
                            </div>
                        )
                    ) : <Skeleton width={190} height={17}/>}
                    {data ? !!data.created && (
                        <div className='club-top__location'>
                            <span>год основания:</span>{data.created}
                        </div>
                    ) : <Skeleton width={190} height={17}/>}
                    {data ? !!data.homeLocation && (
                        <div className='club-top__location'>
                            <span>стадион:</span>{data.homeLocation}
                        </div>
                    ) : <Skeleton width={190} height={17}/>}
                </div>

                <div className='club-top__primary'>
                    {data ? !!data.inSearch && (
                        <div className='club-top__location'>
                            <i className='pi pi-search'/>
                            клуб в поиске игроков
                        </div>
                    ) : <Skeleton width={190} height={17}/>}
                    {data ? !!data.phone && (
                        <div className='club-top__location'>
                            <span>телефон:</span>{data.phone}
                        </div>
                    ) : <Skeleton width={190} height={17}/>}
                    {data ? !!data.email && (
                        <div className='club-top__location'>
                            <span>email:</span>{data.email}
                        </div>
                    ) : <Skeleton width={190} height={17}/>}
                </div>
            </div>

            <div className='club-top_meta'>
                <div className='club-top_subscriptions'>
                    <div className='club-top_subscriptions_title'>подписчиков</div>
                    <div className='club-top_subscriptions_value alterfont'>
                        <div>{favoritedBy}</div>
                    </div>
                    <div className='club-top_subscriptions_icon'>
                        {data ? (
                            <span className='pi pi-star-fill'></span>
                        ) : <Skeleton width={36} height={36}/>}
                    </div>
                </div>
            </div>

            {data && data.teams && data.teams.length > 1 ? (
                <div className='club-top_teams'>
                    <div className='title'>Выберите команду:</div>
                    <div className='list'>
                        {data.teams.sort((a, b) => b.canonical - a.canonical).map((t, i) => (
                            <Tag
                                className={team === t._id ? 'selected' : ''}
                                key={i}
                                onClick={() => {
                                    setTeam(t._id)
                                    window.history.pushState({}, null, `${window.location.pathname}${injectQueryPath('team', t._id)}`)
                                }}
                            >{formatTeamName(t.name)}</Tag>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const socials = {
    vk: vk,
    tg: tg,
    yt: yt
}

export default Top
