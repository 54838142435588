import {CONF} from "../../configs";

export const tabs = [
    {
        label: 'Статистика',
        icon: 'pi pi-chart-pie',
        path: 'stats'
    },
    {
        label: 'Результаты',
        icon: 'pi pi-bolt',
        path: 'results'
    },
    {
        label: 'Календарь',
        path: 'schedule',
        icon: 'pi pi-calendar'
    }
]

export const navi = [
    [
        {
            label: 'Таблица',
            icon: 'trophy',
            path: 'standings'
        },
        {
            label: 'Шахматка',
            icon: 'scoreboard',
            path: 'chess'
        },
        {
            label: CONF.configName === 'lfl36' ? 'Бомбардиры' : 'Снайперы',
            icon: 'ball',
            path: 'scorers'
        },
        {
            label: 'Ассисты',
            icon: 'boots',
            path: 'assists'
        },
        // {
        //     label: 'mvp',
        //     icon: 'medal',
        //     path: 'mvp'
        // },
        {
            label: 'Карточки',
            icon: 'cards',
            path: 'cards'
        }
    ],
    [
        {
            label: 'По дате',
            icon: 'calendar',
            path: 'bydate'
        },
        {
            label: 'По туру',
            icon: 'league',
            path: 'bytour'
        }
    ],
    [
        {
            label: 'По дате',
            icon: 'calendar',
            path: 'bydate'
        },
        {
            label: 'По туру',
            icon: 'league',
            path: 'bytour'
        }
    ]
]
